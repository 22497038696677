import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { competicaoStore } from '@/utils/store-accessor';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Nome', value: 'nome' },
            { text: 'Entrada', value: 'total_entrada' },
            { text: 'Saída', value: 'total_saida' },
            { text: 'Saldo', value: 'total_saldo' },
        ];
        this.search = '';
        this.loading = false;
        this.items = [];
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-competicao-edit', params: { id } });
    }
    async mounted() {
        this.loading = true;
        this.items = await competicaoStore.getCompeticoes();
        this.loading = false;
    }
};
List = __decorate([
    Component
], List);
export default List;
